.content {
  width: 100%;
  padding: 4px 10px;
  background-color: #323130;
}

.link {
  cursor: pointer;
  color: #FFFFFF;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  border-radius: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
}

.link:hover {
  text-decoration: none;
  background-color: gray;
}