
.app {
  height: 100%;
  max-width: 1200px;
}

.nomenurightpanel {
  height: 100%;
}

.rightpanel {
  width: auto;
  padding: 5px 10px;
  overflow: hidden;
  height: 100%;
}

.clear {
  clear: both;
}

.segmenttitle {
  font-size: 1.17em;
  font-weight: bold;
  padding: 5px 0;
  margin: 15px 0;
}

.content {
  height: calc(100% - 85px);
}

.nomenu {
  display: none;
}

.menu {
  width: 140px;
  float: left;
}

.copyright {
  width: 100%;
  padding: 10px 0;
}

.footer {
  background-color: lightgray;
  font-size: 12px;
  text-align: center;
}
