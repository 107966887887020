.songsearchbox {
    line-height: 24px;
    width: calc(100% - 120px);
    max-width: 450px;
}

.folders, .searchbutton {
    height: 30px;
    width: 60px;
}

.songsContent {
    width: 100%;
    padding: 5px 5px;
    height: calc(100% - 500px);
}

.searchresult {
  width:  100%;
  margin-bottom: 10px;
  padding-left: 10;
  padding-right: 10;
  box-shadow: 0 0.3px 0.9px rgba(0,0,0,.108), 0 1.6px 3.6px rgba(0,0,0,.132);
  height: calc(100% - 80px);
 }

.songcell {
    display: inline-block;
    width: "100%";
}

.songtags {
  padding: 0 10px;
  float: "right"
}

.commandcell {
  width: 32px;
  display: inline-block;
}

.youtubecell {
  width: 32px;
  display: inline-block;
}

  .addsongbutton{
    margin-left: 15px;
    cursor: pointer;
    color: blue;
  }

  .songlistnavlink {
    color: blue;
    padding: 0 3px;
    cursor: pointer;
  }

  .songlistnavlink:hover {
    color: gray;
    text-decoration: underline;
  }

  .songTitle {
    cursor: pointer;
  }

  .songtitletext {
    width: 100%;
    height: 28px;
  }

  .songTitle:hover {
    color: blue;
    text-decoration: underline;
  }

  .lyrics {
    width: 100%;
    height: 300px;
    margin-top: 10px;
  }

  .youtubeurl {
    width: 100%px;
    height: 26px;
    margin-top: 10px;
  }

  .songlistaction {
    cursor: pointer;
    color: blue;
  }

  .praiseeventview {
    margin-top: 25px;
  }

  .pariseevents {
    height: 28px;
    padding: 5px 0;
    margin: 5px 0;
  }
