.content {
    height: calc(100% - 10px);
    width: 100%;
    display: inline-block;
    overflow: auto;
    padding: 5px 0px;
}

.activepagetag,
.pagetag,
.first,
.last{
    float: left;
    padding-right: 10px;
    cursor: pointer;
}

.activepagetag {
    color: blue;
}


